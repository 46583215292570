// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-consultation-form-confirmation-tsx": () => import("./../../../src/pages/consultation-form-confirmation.tsx" /* webpackChunkName: "component---src-pages-consultation-form-confirmation-tsx" */),
  "component---src-pages-dentist-form-confirmation-tsx": () => import("./../../../src/pages/dentist-form-confirmation.tsx" /* webpackChunkName: "component---src-pages-dentist-form-confirmation-tsx" */),
  "component---src-pages-general-form-confirmation-tsx": () => import("./../../../src/pages/general-form-confirmation.tsx" /* webpackChunkName: "component---src-pages-general-form-confirmation-tsx" */),
  "component---src-pages-invisalign-form-confirmation-tsx": () => import("./../../../src/pages/invisalign-form-confirmation.tsx" /* webpackChunkName: "component---src-pages-invisalign-form-confirmation-tsx" */),
  "component---src-pages-patient-form-confirmation-tsx": () => import("./../../../src/pages/patient-form-confirmation.tsx" /* webpackChunkName: "component---src-pages-patient-form-confirmation-tsx" */),
  "component---src-pages-smile-form-confirmation-tsx": () => import("./../../../src/pages/smile-form-confirmation.tsx" /* webpackChunkName: "component---src-pages-smile-form-confirmation-tsx" */),
  "component---src-pages-treatment-form-confirmation-tsx": () => import("./../../../src/pages/treatment-form-confirmation.tsx" /* webpackChunkName: "component---src-pages-treatment-form-confirmation-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-master-template-tsx": () => import("./../../../src/templates/masterTemplate.tsx" /* webpackChunkName: "component---src-templates-master-template-tsx" */)
}

